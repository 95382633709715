const bannerPlayer = new Plyr('#banner-player');

const eventsSwiper = new Swiper('#events-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 15,

  navigation: {
    nextEl: '#events-swiper-next',
    prevEl: '#events-swiper-prev',
  },
});

const newsSwiper = new Swiper('#news-swiper', {
  slidesPerView: 2,
  spaceBetween: 10,

  navigation: {
    nextEl: '#news-swiper-next',
    prevEl: '#news-swiper-prev',
  },
});

eventsSwiper.slides[2]?.classList.add('home-events__item--full');

eventsSwiper.on('slideChange', () => {
  eventsSwiper.slides.forEach((slide) => {
    slide.classList.remove('home-events__item--full');
  });
  const slide = eventsSwiper.slides[eventsSwiper.activeIndex + 2];
  slide.classList.add('home-events__item--full');
});

const newsSchoolFilter = document.querySelectorAll(
  '#news-schools-filter button'
);
const newsSchoolList = document.querySelectorAll('#news-schools-list li');

newsSchoolFilter.forEach((button) => {
  button.addEventListener('click', () => {
    const isSelected = button.dataset.active === 'true';
    const blog = button.dataset.blog;

    if (isSelected) {
      button.dataset.active = 'false';

      newsSchoolList.forEach((item) => {
        item.classList.remove('home-news__item--hidden');
      });
    } else {
      newsSchoolFilter.forEach((item) => {
        const buttonBlog = item.dataset.blog;
        buttonBlog === blog
          ? (item.dataset.active = 'true')
          : (item.dataset.active = 'false');
      });

      newsSchoolList.forEach((item) => {
        const itemBlog = item.dataset.blog;
        blog === itemBlog
          ? item.classList.remove('home-news__item--hidden')
          : item.classList.add('home-news__item--hidden');
      });
    }

    newsSwiper.update();
  });
});

const video = document.querySelector('#home-video');
const videoButton = document.querySelector('#home-video-ctrl');

videoButton.addEventListener('click', () => {
  if (video.paused) {
    video.play();
    videoButton.setAttribute('data-paused', 'false');
  } else {
    video.pause();
    videoButton.setAttribute('data-paused', 'true');
  }
});
